// web
const routes = [{
    path: '/web/home',
    name: '首页',
    component: () =>
      import('@/views/WEBpages/Home.vue')
  },
  {
    path: '/web/journalism',
    name: '新闻中心',
    component: () => import('@/views/WEBpages/Journalism.vue')
  },
  {
    path: '/web/product',
    name: '项目与产品',
    redirect: '/web/product/couldDicom',
    component: () => import('@/views/WEBpages/product/index.vue'),
    children: [
      {
        path: 'couldDicom',
        name: '云影像',
        component: () => import('@/views/WEBpages/product/dicomCloud/index.vue')
      },
      {
        path: 'dicomCloudStorage',
        name: '影像云存储',
        component: () => import('@/views/WEBpages/product/dicomStorage/index.vue')
      },
      {
        path: 'regionalCloudDicom',
        name: '区域云影像',
        component: () => import('@/views/WEBpages/product/regionalCloudDicom/index.vue')
      }
    ]
  },
  {
    path: '/web/about',
    name: '关于我们',
    component: () =>
      import('../../views/WEBpages/About.vue')
  },
  {
    path: '/web/recruit',
    name: '人才招聘',
    component: () =>
      import('../../views/WEBpages/Recruit.vue')
  },
  {
    path: '/web/journalism/details',
    name: '新闻详情',
    component: () =>
      import( /* webpackChunkName: "about" */ '../../views/WEBpages/DetailsJournalism.vue')
  }
]
export default routes
