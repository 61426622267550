import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index';

// 引入 初始化 css 的文件
import './assets/css/reset.css'
// 引入 element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

import VueQuillEditor from 'vue-quill-editor'

// 引入 自定义 css 的文件
import './assets/css/index.less'
import './assets/iconfont/iconfont.css'

// 引入moment时间
// import moment from 'moment'

// 处理时间
// Vue.filter('formatDate', val => {
//   return moment(val).format('YYYY-MM-DD HH:mm:ss')
// })
Vue.use(ElementUI)
Vue.use(VueQuillEditor /* { default global options } */ )
// Vue.use(quillEditor)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
