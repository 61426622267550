import Vue from 'vue'
import VueRouter from 'vue-router'
import web from './web/index'
import phone from './phone/index'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 基本路由
const routes = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/WEBpages/index.vue'),
    redirect: '/homepage',
    children: web
  },
  {
    path: '/phone',
    component: () =>
      import( /* webpackChunkName: "about" */ '../views/PhonePages/index.vue'),
    redirect: '/phone/home',
    children: phone
  },
  {
    path: '*',
    redirect: '/web/home'
  }
]
const router = new VueRouter({
  routes,
  mode: 'hash'
})

// 导航守卫 - 在每一次跳转路由之间介入
router.beforeEach((to, from, next) => {
 if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
    if (to.path.indexOf('/phone/') === -1) {
      next(to.path.replace('/web/','/phone/'))
    } else {
      next()
    }
  } else {
    console.log(document.body.clientWidth,'屏幕宽度');
    if (to.path.indexOf('/web/') === -1) {
      next(to.path.replace('/phone/','/web/'))
    } else {
      next()
    } 
  }
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  next()
})

export default router
