// phone
const routes = [{
    path: '/phone/home',
    name: '首页',
    component: () =>
      import('@/views/PhonePages/PhoneHome.vue')
  },
  {
    path: '/phone/product',
    name: '项目与产品',
    redirect: '/phone/product/couldDicom',
    component: () =>
      import('@/views/PhonePages/product/index.vue'),
      children: [
        {
          path: 'couldDicom',
          name: '云影像',
          component: () => import('@/views/PhonePages/product/dicomCloud/index.vue')
        },
        {
          path: 'dicomCloudStorage',
          name: '影像云存储',
          component: () => import('@/views/PhonePages/product/dicomStorage/index.vue')
        },
        {
          path: 'regionalCloudDicom',
          name: '区域云影像',
          component: () => import('@/views/PhonePages/product/regionalCloudDicom/index.vue')
        }
      ]
      
  },
  {
    path: '/phone/journalism',
    name: '新闻中心',
    component: () =>
      import('../../views/PhonePages/Journalism.vue')
  },
  {
    path: '/phone/journalismdetails',
    name: '',
    component: () =>
      import('@/views/PhonePages/DetailsJournalism.vue')
  },
  {
    path: '/phone/recruit',
    name: '人才招聘',
    component: () =>
      import('@/views/PhonePages/Recruit.vue')
  },
  {
    path: '/phone/account',
    name: '公众号',
    component: () =>
      import('@/views/PhonePages/OfficialAccount.vue')
  }, {
    path: '/phone/about',
    name: '关于我们',
    component: () =>
      import('@/views/PhonePages/About.vue')
  },

]
export default routes
